<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('amount')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="2"></el-option>
            <el-option :label="$t('getApproved')" value="3"></el-option>
            <el-option :label="$t('notApproved')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns" v-if="loginInfo.orgType!=1">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="drawer=true">{{$t('cashHandInApply')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" width="60"></el-table-column>
        <el-table-column prop="createTime" :label="$t('time')" min-width="160">
          <template slot-scope="scope">
            {{$manba(scope.row.createTime).format('ymdhms')}}
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="text-danger">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==3" class="text-success">{{$t('consentHandIn')}}</span>
            <span v-if="scope.row.status==4" class="text-warning">{{$t('refuseHandIn')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" min-width="200"></el-table-column>
        <el-table-column prop="checkRemark" :label="$t('checkRemark')" min-width="200"></el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('cashHandInApply')" :visible.sync="drawer" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false">
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item :label="$t('amount')" prop="amount">
          <el-input type="number" size="medium" v-model="addForm.amount" maxlength="10" />
        </el-form-item>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input type="textarea" size="medium" v-model="addForm.remark" />
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmit('addForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import card from '@/api/card'
export default {
  data () {
    return {
      queryForm: {},
      isLoading: false,
      queryTableHeight: String,
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      drawer: false,
      addForm: {},
      tableData: {},
    }
  },
  computed: {
    rules () {
      return { amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }] }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    getList () {
      let _this = this;
      card.pageCashReceive({
        param: { current: _this.currentPage, size: _this.pageSize, type: 1, loginName: _this.loginInfo.loginName, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    onSubmit (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return valid;
        let _this = this;
        card.cashToHandIn({
          param: _this[refName],
          success: res => {
            if (res.code == 200) {
              _this.getList();
              _this.drawer = false;
              _this.$message.success(_this.$t('operateSuccessfully'))
              _this[refName] = {};
            }
            else
              _this.$message.warning(_this.$t(res.msg))
          }
        })
      })
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
  }
}
</script>
<style lang="less" scoped>
</style>